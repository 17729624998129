<template>
  <div class="fill-height bg-white">
    <Navbar />
    <div class="main-body custom-height">
      <CurrentBroadcasts />
      <div class="columns-wrapper newDash-column-wrapper">
        <div class="columns-wrap-row">
          <div class="col-4 col-first">
            <div class="new-col">
              <div class="fill-height">
                <div class="new-col-head">
                  <div class="messsge-search box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
                    <div class="box-top-wrap-left">
                      <div>
                        <h3 class="box-title">
                          {{ $t("posts.posting_calender") }}
                        </h3>
                      </div>
                      <div class="c-datepicker">
                        <v-icon @click="resetDateFilter()">mdi-close</v-icon>
                        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                        min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" elevation="0">
                              <v-icon>mdi-calendar-edit</v-icon>
                            </v-btn>
                          </template>
                          <v-date-picker color="green darken-4" v-model="date" @input="menu2 = false"
                          @change="loadMorePosts(); inventoryLoadMorePosts();"></v-date-picker>
                          </v-menu>
                      </div>
                    </div>
                    <div class="box-top-wrap-right">
                      <div class="btn-msg d-flex">
                        <div>
                          <div class="glow"></div>
                          <button
                            :class="badgeCount !== 0 ? 'btn customBtn pattern-btn dark-gray-btn view-message' : 'btn customBtn pattern-btn dark-gray-btn'"
                            elevation="0" @click="$router.push({ name: 'MessageCenter' }).catch((err) => { })">
                            <v-icon :class="badgeCount !== 0 ? 'view-message-icon' : ''">mdi-email</v-icon>
                            <span class="btn-text">{{ $t("posts.messages") }}</span>
                            <!-- :content="badgeCount" v-if="badgeCount !== 0" -->
                          </button>
                        </div>
                        <small :content="badgeCount" v-if="badgeCount !== 0" class="msg-count">{{ badgeCount
                        }}</small>
                        <span v-if="$vuetify.breakpoint.width < 501" class="ml-1">
                          <button @click="setDialog(false)" class="create-post-btn btn customBtn pattern-btn">
                            <span>{{ $t("posts.createPost") }}</span>
                          </button>
                        </span>
                      </div>
                      <span v-if="$vuetify.breakpoint.width > 500">
                        <button @click="setDialog(false)" class="create-post-btn btn customBtn pattern-btn">
                          <span>{{ $t("posts.createPost") }}</span>
                        </button>
                      </span>
                      <button class="btn customBtn pattern-btn search-btn-sml" @click="toggleSearch(1)" style="display:none;">
                        <span><img src="./../assets/img/icon-search.svg" alt="" /></span>
                      </button>
                    </div>
                  </div>
                  <span v-if="expandedSearch === 1" class="new-post-search">
                    <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
                      :placeholder="$t('posts.searchPost')" solo @input="searchPosts"
                      prepend-inner-icon="mdi-magnify"></v-text-field>
                  </span>
                </div>
                <div class="new-dashboard-box inside-model-box"
                  :class="expandedSearch === 1 ? 'search-expand-active' : ''">
                  <div class="new-post-list">
                    <ul>
                      <li v-for="(item, index) in allPosts" :key="index"
                        v-if="item.post_type == 0 || item.post_type == 1 || item.post_type == 3"
                        :class="getRowClass(item)">
                        <div class="post-row-inr">
                          <!-- all type image -->
                          <div class="left-img-wrap"  v-if="(adminTeamUser.includes(item?.owner?.id) && !item.medias.length) || (item.phone && (item.post_type == 0 || item.post_type == 1)) || (!item.phone && item.post_type == 1) || (item.post_type == 3)">
                            <div :class="['left-data', adminTeamUser.includes(item?.owner?.id) ? 'user-2-new' : '',
                              (item.phone && (item.post_type == 0 || item.post_type == 1)) ? 'user-enquiry-new' : '']">
                              <div v-if="adminTeamUser.includes(item?.owner?.id) && !item.medias.length"
                                class="announcement-img">
                                <img src="./../assets/img/annoncement-megaphone.svg" alt="">
                              </div>
                              <div v-if="(item.phone && (item.post_type == 0 || item.post_type == 1) || (!item.phone && item.post_type == 1))" class="enquiry-img">
                                <img src="./../assets/img/users-svgrepo.svg" alt="">
                              </div>
                              <div v-if="item.post_type == 3" :class="['text-pre-wrap post-with-image rd-post-with-image',
                                adminTeamUser.includes(item?.owner?.id) ? 'user-2-new' : '']">
                                <div class="post-with-images-img" v-if="item.medias.length">
                                  <div v-if="item?.medias[0]?.id == attentionPost.id">
                                    <img :src="attentionPost.url100" alt="">
                                  </div>
                                  <div v-else @click="setImageSlider(item)">
                                    <img :src="item.medias[0].url100" alt="">
                                    <span class="click-on-it">{{ $t("posts.clickOnIt") }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- all type image end -->

                          <div :class="['right-data', adminTeamUser.includes(item?.owner?.id) ? 'user-2-new' : '', (item.phone && (item.post_type == 0 || item.post_type == 1)) ? 'user-enquiry-new' : '']">
                             <!-- POST HEADER START -->
                            <div v-if="!item.phone &&  item.post_type == 0" :class="['post-header']">
                              <div>
                                <p v-if="adminTeamUser.includes(item?.owner?.id)" class="ans-label"> Important Announcement</p>
                                <div class="date-user-wrap">
                                  <span class="datebreak date-time">
                                    <v-icon aria-hidden="false">   mdi-clock-time-five-outline </v-icon>
                                    <span>{{ item.created_at | DateTimezoneFilter }}</span>
                                  </span>
                                  <span class="rd-post-icon-content rd-user-name" v-if="item.owner">
                                    <v-icon aria-hidden="false">  mdi-account-outline  </v-icon>
                                    <span>{{ $t("posts.by") }}: {{ item.owner.name }}</span>
                                  </span>
                                </div>
                              </div>
                              <div
                                v-if="!item.phone && (!item.medias.length && item.post_type == 0)">
                                <div class="text-right">
                                  <button v-show="item?.owner?.id != user.id" :disabled="item.is_replied === true"
                                    class="btn customBtn pattern-btn text-capitalize white-text"
                                    @click="setDialog(true, item)">
                                    <span>{{ item.owner == null ? $t("posts.replycall") : $t("posts.reply") }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            
                            <div v-if="item.phone && item.post_type == 0" :class="['text-pre-wrap user-enquiry-new1 post-header']">
                              <div>
                                <p class="ans-label">ENQUIRY from Direct Customer</p>
                                <div class="date-user-wrap">
                                  <span class="datebreak date-time">
                                    <v-icon aria-hidden="false">  mdi-clock-time-five-outline </v-icon>
                                    <span>{{ item.created_at | DateTimezoneFilter }}</span>
                                  </span>
                                  <span class="rd-user-phone rd-post-icon-content">
                                    <img src="./../assets/img/phone-outgoing-1.svg" alt="">
                                    {{ item.phone.substring(2) | USFormatNumber }}
                                  </span>
                                </div>
                              </div>
                              <div class="text-right">
                                <button :disabled="item.is_replied === true"
                                  class="btn customBtn pattern-btn text-capitalize white-text"
                                  @click="setDialog(true, item)">
                                  <span>{{ item.owner == null ? $t("posts.replycall") : $t("posts.reply") }}</span>
                                </button>
                              </div>
                            </div>

                            <div v-if="item.post_type == 1" :class="['text-pre-wrap user-enquiry-new1 post-header']">
                              <div>
                                <p class="ans-label">ENQUIRY from Direct Customer</p>
                                <div class="date-user-wrap">
                                  <span class="datebreak date-time">
                                    <v-icon aria-hidden="false">  mdi-clock-time-five-outline </v-icon>
                                    <span>{{ item.created_at | DateTimezoneFilter }}</span>
                                  </span>
                                  <span class="rd-user-phone rd-post-icon-content" v-if="item.phone">
                                    <img src="./../assets/img/phone-outgoing-1.svg" alt="">
                                    {{ item.phone.substring(2) | USFormatNumber }}
                                  </span>
                                </div>
                              </div>
                              <div class="text-right">
                                <!-- No button for these type of posts  -->
                              </div>
                            </div>
                          
                            <div v-if="!item.phone && item.post_type == 3" :class="['post-header']">
                              <div>
                                <p v-if="adminTeamUser.includes(item?.owner?.id)" class="ans-label">Important Announcement</p>
                                <div class="date-user-wrap">
                                  <span class="datebreak date-time">
                                    <v-icon aria-hidden="false">   mdi-clock-time-five-outline </v-icon>
                                    <span>{{ item.created_at | DateTimezoneFilter }}</span>
                                  </span>
                                  <span class="rd-post-icon-content rd-user-name" v-if="item.owner">
                                    <v-icon aria-hidden="false">  mdi-account-outline  </v-icon>
                                    <span>{{ $t("posts.by") }}: {{ item.owner.name }}</span>
                                  </span>
                                </div>
                              </div>
                              <div v-if="!item.phone &&  item.post_type == 3">
                                <div class="text-right">
                                  <button v-show="item?.owner?.id != user.id" :disabled="item.is_replied === true"
                                    class="btn customBtn pattern-btn text-capitalize white-text"
                                    @click="setDialog(true, item)">
                                    <span>{{ item.owner == null ? $t("posts.replycall") : $t("posts.reply") }}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            
                             <!-- POST HEADER END -->

                            <!-- POST MESSAGE START -->
                            <div v-if="!item.phone && !item.medias.length && item.post_type == 0"
                              :class="['post--message-wrap text-pre-wrap', adminTeamUser.includes(item?.owner?.id) ? 'user-2-new1' : '']">
                              <span>
                                {{ item.message }}
                              </span>
                            </div>
                            <div v-if="item.phone && (item.post_type == 0 || item.post_type == 1) || (!item.phone && item.post_type == 1)"
                              :class="['post--message-wrap text-pre-wrap user-enquiry-new1']">
                              <span>{{ capitalizeFirstLetter(item.message) }}
                              </span>
                            </div>
                            <div v-if="item.post_type == 3" :class="['post--message-wrap text-pre-wrap post-with-image rd-post-with-image',
                              adminTeamUser.includes(item?.owner?.id) ? 'user-2-new' : '']">
                              <span>
                                {{ item.message }}
                              </span>
                            </div>
                            <!--POST MESSAGE END -->
                          </div>
                        </div>
                      </li>
                      <li v-if="noHasMorePosts" class="show-more-wrap">
                        <button class="btn customBtn pattern-btn text-capitalize" disabled>
                          <span>{{ $t("posts.no_more_post") }}</span>
                        </button>
                      </li>
                      <li v-if="hasMorePosts" @click="loadMorePosts(false)" class="show-more-wrap">
                        <button class="show-more-btn btn customBtn pattern-btn">
                          <span>{{ $t("posts.showmore") }}</span>
                        </button>
                      </li>
                    </ul>
                  </div>

                  <v-dialog hide-overlay :attach="true" v-model="dialog.open" max-width="500px"
                    content-class="rd-custom-modal">
                    <v-card>
                      <v-card-title>
                        <h4>{{ dialog.title }}</h4>
                        <button class="close-btn" @click="dialog.open = !dialog.open">
                          <img src="./../assets/img/close.svg" alt="">
                        </button>
                      </v-card-title>
                      <v-card-text>
                        <div class="post-reply-wrap">
                          <v-textarea v-show="dialog.title === $t('posts.createPost')" v-model="message"
                            :placeholder="$t('posts.typeYourMessageHere')" :error-messages="messageError"
                            @blur="$v.message.$touch()" outlined dense solo name="input-7-4" spellcheck="false">
                          </v-textarea>
                          <v-textarea v-show="dialog.title === $t('posts.replyOnPost')" v-model="reply"
                            :placeholder="$t('posts.enterText')" :error-messages="replyError" @blur="$v.reply.$touch()"
                            outlined dense solo name="input-7-4" spellcheck="false"></v-textarea>
                          <emoji-picker v-show="dialog.title === $t('posts.createPost')" @emoji="append"
                            :search="search">
                            <div class="emoji-invoker" slot="emoji-invoker"
                              slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                              <svg class="emojipickers" height="24" viewBox="0 0 24 24" width="24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                  d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                              </svg>
                            </div>
                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                              <div class="emoji-picker" :style="{
                                position: 'relative',
                                float: 'right',
                                bottom: '226px',
                              }">
                                <div class="emoji-picker__search">
                                  <input type="text" v-model="search" v-focus />
                                </div>
                                <div>
                                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                                      <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName"
                                        @click="insert(emoji)" :title="emojiName">
                                        {{ emoji }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </emoji-picker>
                        </div>

                        <div v-if="adminTeamUser.includes(user.id)" v-show="dialog.title === $t('posts.createPost')"
                          class="
                  flex
                  w-full
                  h-screen
                  items-center
                  justify-center
                  text-center
                  fileupload custom-fileUpload
                " id="app" style="min-height: 56px;margin-bottom:15px">
                          <v-progress-circular v-if="fileLoader" indeterminate :size="90"
                            color="green"></v-progress-circular>
                          <div class="" v-if="!fileLoader">
                            <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose"
                              :preview=false :className="['d-none']" @input="onFileChange($event, 'img1')">
                              <label for="fileInput" slot="upload-label" class="node-image-uploader">
                                <figure>
                                  <img src="./../assets/img/dummy-img.svg" alt="" />
                                </figure>
                                <span class="upload-caption">{{ $t("posts.clickToUpload") }}</span>
                              </label>
                            </image-uploader>
                          </div>
                          <div id="img1" class="select-post-image">
                            <img v-if="image" class="img1" :src='image?.url100' alt='' style="border-radius: 8px;">
                            <a id="img1-delIcon" v-if="image" @click="deleteImage('img1')" class="delicon"
                              data-tooltip="Delete">
                              <!-- <img src="./../assets/img/trash.svg" alt="" /> -->
                              <v-icon>mdi-delete</v-icon>
                            </a>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <img v-show="dialog.title === $t('posts.replyOnPost')" height="25" width="25"
                          src="./../assets/img/google_g_icon.svg" />
                        <a v-show="dialog.title === $t('posts.replyOnPost')" :href="dialog.link" target="_blank"
                          class="google-text">
                          {{ $t("posts.googleReviews") }}
                        </a>
                        <v-spacer></v-spacer>
                        <button class="btn customBtn red-fill-btn pattern-btn" @click="dialog.open = !dialog.open">
                          <span>{{ dialog.closeButtonText }}</span>
                        </button>
                        <v-btn class="btn customBtn pattern-btn ml-2" elevation="0" :loading="btnLoading"
                          @click="callDialogMethod" :disabled="(dialog.isForCreatePost &&
                            $v.message.$invalid &&
                            $v.message.$dirty) ||
                            (!dialog.isForCreatePost &&
                              $v.reply.$invalid &&
                              $v.reply.$dirty)
                            ">
                          <span> {{ dialog.saveButtonText }}</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog hide-overlay :attach="true" v-model="imageSlider" max-width="95%" width="auto" margin="0">
                    <v-carousel class="carousel-wrapper post-img-wrapper" :show-arrows="false" hide-delimiters
                      height="auto">
                      <span class="carousel-close-icon" @click="imageSlider = false">
                        <v-icon>mdi-close</v-icon>
                      </span>
                      <v-carousel-item v-for="(item, i) in images" :key="i">
                        <img :src="item.social" style="width: 100%; height: 100%" :alt="item.social" eager />
                      </v-carousel-item>
                    </v-carousel>
                  </v-dialog>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 col-second">
            <div class="new-col">
              <div class="fill-height">
                <div class="new-col-head">
                  <div class="messsge-search box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
                    <div class="box-top-wrap-left">
                      <div>
                        <h3 class="box-title">
                          {{ $t("posts.engine_transmission") }}
                        </h3>
                      </div>
                    </div>
                    <div class="box-top-wrap-right">
                      <button class="btn customBtn pattern-btn search-btn-sml" @click="toggleSearch(2)" style="display:none;">
                        <span><img src="./../assets/img/icon-search.svg" alt="" /></span>
                      </button>
                    </div>
                  </div>
                  <span v-if="expandedSearch === 2" class="new-post-search">
                    <v-text-field hide-details="auto" v-model="searchInventoryText" elevation="0" height="40" dense
                      :placeholder="$t('posts.searchInventory')" solo @input="searchPartsInput"
                      prepend-inner-icon="mdi-magnify"></v-text-field>
                  </span>
                </div>
                <div class="new-dashboard-box inside-model-box" :class="expandedSearch === 2 ? 'search-expand-active' : ''">
                  <div class="new-post-list">
                    <ul>
                      <li v-for="(Inventoryitem, index) in inventoryAllPosts" :key="index"
                        v-if="!Inventoryitem.phone && Inventoryitem.post_type == 2" :class="getRowClass(Inventoryitem)">
                        <div class="post-row-inr">
                          <!-- all type image -->
                          <div v-if="Inventoryitem.medias.length" class="left-img-wrap">
                            <div>
                              <div v-if="!Inventoryitem.phone && Inventoryitem.post_type == 2" :class="['text-pre-wrap post-with-image rd-post-with-image']">
                                <div class="post-with-images-img" v-if="Inventoryitem.medias.length">
                                  <div v-if="Inventoryitem?.medias[0]?.id == attentionPost.id">
                                    <img :src="attentionPost.url100" alt="">
                                  </div>
                                  <div v-else @click="setInventoryImageSlider(Inventoryitem)">
                                    <img :src="Inventoryitem.medias[0].url100" alt="">
                                    <span class="click-on-it">{{ $t("posts.clickOnIt") }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- all type image -->

                          <div class="right-data">
                           
                            <div v-if="!Inventoryitem.phone && Inventoryitem.post_type == 2" :class="['post-header text-pre-wrap post-with-image rd-post-with-image']">
                              <div>
                                <div class="date-user-wrap">
                                  <span  v-if="!Inventoryitem.phone && (Inventoryitem.post_type == 0 || Inventoryitem.post_type == 2 || Inventoryitem.post_type == 3)" class="datebreak date-time">
                                    <v-icon aria-hidden="false">  mdi-clock-time-five-outline  </v-icon>
                                    <span>{{ Inventoryitem.created_at | DateTimezoneFilter }}</span>
                                  </span>
                                  <span class="rd-post-icon-content rd-user-name"  v-if="Inventoryitem.owner && (Inventoryitem.post_type == 0 || Inventoryitem.post_type == 2 || Inventoryitem.post_type == 3)">
                                    <v-icon aria-hidden="false" style="display:none;">  mdi-account-outline  </v-icon>
                                    <span>{{ $t("posts.by") }}: {{ Inventoryitem.owner.name }}</span>
                                  </span>
                                </div>
                              </div>
                              <div class="text-right">
                                <button v-show="Inventoryitem?.owner?.id != user.id"
                                  :disabled="Inventoryitem.is_replied === true"
                                  class="btn customBtn pattern-btn text-capitalize white-text"
                                  @click="setDialog(true, Inventoryitem, true)">
                                  <span>{{ Inventoryitem.owner == null ? $t("posts.replycall") : $t("posts.reply")
                                  }}</span>
                                </button>
                              </div>
                            </div>

                            <div v-if="!Inventoryitem.phone && Inventoryitem.post_type == 2" :class="['post--message-wrap text-pre-wrap',
                              adminTeamUser.includes(Inventoryitem?.owner?.id) ? 'user-2-new' : '']">
                              <div>
                                <span class="sale-label">{{ $t("posts.forSale") }}</span> {{Inventoryitem.message.replace('$0.00','Call') }} 
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li v-if="noInventoryHasMorePosts" class="show-more-wrap">
                        <button class="btn customBtn pattern-btn text-capitalize" disabled>
                          <span>{{ $t("posts.no_more_post") }}</span>
                        </button>
                      </li>
                      <li v-if="inventoryHasMorePosts" @click="inventoryLoadMorePosts(false)" class="show-more-wrap">
                        <button class="show-more-btn btn customBtn pattern-btn">
                          <span>{{ $t("posts.showmore") }}</span>
                        </button>
                      </li>
                    </ul>
                  </div>

                  <v-dialog hide-overlay :attach="true" v-model="dialog.inventoryOpen" max-width="500px"
                    content-class="rd-custom-modal">
                    <v-card>
                      <v-card-title>
                        <h4>{{ dialog.title }}</h4>
                        <button class="close-btn" @click="dialog.inventoryOpen = !dialog.inventoryOpen">
                          <img src="./../assets/img/close.svg" alt="">
                        </button>
                      </v-card-title>
                      <v-card-text>
                        <div class="post-reply-wrap">
                          <v-textarea v-show="dialog.title === $t('posts.createPost')" v-model="message"
                            :placeholder="$t('posts.typeYourMessageHere')" :error-messages="messageError"
                            @blur="$v.message.$touch()" outlined dense solo name="input-7-4" spellcheck="false">
                          </v-textarea>
                          <v-textarea v-show="dialog.title === $t('posts.replyOnPost')" v-model="reply"
                            :placeholder="$t('posts.enterText')" :error-messages="replyError" @blur="$v.reply.$touch()"
                            outlined dense solo name="input-7-4" spellcheck="false"></v-textarea>
                          <emoji-picker v-show="dialog.title === $t('posts.createPost')" @emoji="append"
                            :search="search">
                            <div class="emoji-invoker" slot="emoji-invoker"
                              slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                              <svg class="emojipickers" height="24" viewBox="0 0 24 24" width="24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                  d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                              </svg>
                            </div>
                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                              <div class="emoji-picker" :style="{
                                position: 'relative',
                                float: 'right',
                                bottom: '226px',
                              }">
                                <div class="emoji-picker__search">
                                  <input type="text" v-model="search" v-focus />
                                </div>
                                <div>
                                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                                      <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName"
                                        @click="insert(emoji)" :title="emojiName">
                                        {{ emoji }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </emoji-picker>
                        </div>

                        <div v-if="adminTeamUser.includes(user.id)" v-show="dialog.title === $t('posts.createPost')"
                          class="
                  flex
                  w-full
                  h-screen
                  items-center
                  justify-center
                  text-center
                  fileupload custom-fileUpload
                " id="app" style="min-height: 56px;margin-bottom:15px">
                          <v-progress-circular v-if="fileLoader" indeterminate :size="90"
                            color="green"></v-progress-circular>
                          <div class="" v-if="!fileLoader">
                            <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose"
                              :preview=false :className="['d-none']" @input="onFileChange($event, 'img1')">
                              <label for="fileInput" slot="upload-label" class="node-image-uploader">
                                <figure>
                                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path class="path1"
                              d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                            </path>
                          </svg> -->
                                  <img src="./../assets/img/dummy-img.svg" alt="" />
                                </figure>
                                <span class="upload-caption">{{ $t("posts.clickToUpload") }}</span>
                              </label>
                            </image-uploader>
                          </div>
                          <div id="img1" class="select-post-image">
                            <img v-if="image" class="img1" :src='image?.url100' alt='' style="border-radius: 8px;">
                            <a id="img1-delIcon" v-if="image" @click="deleteImage('img1')" class="delicon"
                              data-tooltip="Delete">
                              <!-- <img src="./../assets/img/trash.svg" alt="" /> -->
                              <v-icon>mdi-delete</v-icon>
                            </a>
                          </div>
                        </div>
                        <!-- <div v-if="adminTeamUser.includes(user.id)" class="checkbox-field">
                  <v-checkbox class="pr-0 pt-0" style="height: 25px" v-model="isAttentionPost"
                    :label="$t('posts.attentionPost')" @change="onCheckAttentionPost()"></v-checkbox>
                </div> -->
                      </v-card-text>
                      <v-card-actions>
                        <img v-show="dialog.title === $t('posts.replyOnPost')" height="25" width="25"
                          src="./../assets/img/google_g_icon.svg" />
                        <a v-show="dialog.title === $t('posts.replyOnPost')" :href="dialog.link" target="_blank"
                          class="google-text">
                          {{ $t("posts.googleReviews") }}
                        </a>
                        <v-spacer></v-spacer>
                        <button class="btn customBtn red-fill-btn pattern-btn"
                          @click="dialog.inventoryOpen = !dialog.inventoryOpen">
                          <span>{{ dialog.closeButtonText }}</span>
                        </button>
                        <v-btn class="btn customBtn pattern-btn ml-2" elevation="0" :loading="btnLoading"
                          @click="callDialogMethod" :disabled="(dialog.isForCreatePost &&
                            $v.message.$invalid &&
                            $v.message.$dirty) ||
                            (!dialog.isForCreatePost &&
                              $v.reply.$invalid &&
                              $v.reply.$dirty)
                            ">
                          <span> {{ dialog.saveButtonText }}</span>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog hide-overlay :attach="true" v-model="inventoryImageSlider" max-width="95%" width="auto"
                    margin="0">
                    <v-carousel class="carousel-wrapper post-img-wrapper" :show-arrows="false" hide-delimiters
                      height="auto">
                      <span class="carousel-close-icon" @click="inventoryImageSlider = false">
                        <v-icon>mdi-close</v-icon>
                      </span>
                      <v-carousel-item v-for="(item, i) in images" :key="i">
                        <img :src="item.social" style="width: 100%; height: 100%" :alt="item.social" eager />
                      </v-carousel-item>
                    </v-carousel>
                  </v-dialog>

                </div>
              </div>
            </div>
          </div>
          <div class="col-4 col-third">
            <div class="new-col">
              <div class="fill-height">
                <div class="new-col-head">
                  <div class="messsge-search box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
                    <div class="box-top-wrap-left">
                      <div>
                        <h3 class="box-title">
                          {{ $t("membersDirectory.title") }}
                        </h3>
                        <p class="box-subtitle"> {{ $t("membersDirectory.dismantler") }} </p>
                      </div>
                    </div>
                    <div class="box-top-wrap-right">
                      <button class="btn customBtn pattern-btn search-btn-sml" @click="toggleSearch(3)">
                        <span><img src="./../assets/img/icon-search.svg" alt="" /></span>
                      </button>
                    </div>
                  </div>
                  <span v-if="expandedSearch === 3" class="new-post-search">
                    <v-text-field hide-details="auto" v-model="searchMemberText" elevation="0" height="40" dense
                      :placeholder="$t('posts.searchMemberDirectory')" solo @input="searchMembers"
                      prepend-inner-icon="mdi-magnify"></v-text-field>
                  </span>
                </div>
                <div class="new-dashboard-box" :class="expandedSearch === 3 ? 'search-expand-active' : ''">
                  <div class="new-directory-list">
                    <ul>
                      <li v-for="item in allMembers" :key="item.id" class="list-item">
                        <div class="new-directory-list-left">
                          <div class="company-info">
                            <strong>{{ item.company_name }}</strong>
                            <br />
                            <a :href="'https://' + item.website" target="_blank" class="light-green-text"
                              v-if="item.website">
                              <img src="./../assets/img/globe.svg" alt="">
                              <span> {{ item.website }}</span>
                            </a>
                          </div>

                          <div class="contact-info">
                            <p>
                              <img src="./../assets/img/map-pin-1.svg" alt="">
                              <span>{{ item.address }}, {{ item.city }}, {{ item.state }}</span>
                            </p>
                            <p>
                              <img src="./../assets/img/phone-outgoing-1.svg" alt="">
                              <span>{{ item.company_phone | USFormatNumber }}</span>
                            </p>
                          </div>
                        </div>

                        <div class="expand-section">
                          <v-btn small class="btn-share btn customBtn gray-sml-btn text-capitalize"
                            @click="toggleExpand(item)">
                            <img src="./../assets/img/share.svg" alt="">
                          </v-btn>
                          <div v-if="expandedItem === item" class="form-custom">
                            <v-card class="pa-0 d-flex align-center" flat>
                              <vue-tel-input class="mr-3" style="width: 100%"
                                :style="phone && phoneError ? 'border-color: red;' : ''" elevation="0" dense
                                v-model="phone" :error-messages="phoneError" validCharactersOnly
                                @blur="$v.phone.$touch()" :inputOptions="{
                                  maxlength: 14,
                                  placeholder: $t('membersDirectory.cellularNumber'),
                                }" :dynamicPlaceholder="true" :disabledFormatting="false" defaultCountry="US"
                                :onlyCountries="['US']" />
                              <v-btn height="35" @click="shareMemberInfo(item)"
                                :disabled="!phone || ($v.phone.$invalid && $v.phone.$dirty)" :loading="loading"
                                class="btn customBtn pattern-btn btn-send-mobile text-capitalize white-text"
                                elevation="0">
                                <span>{{ $t("membersDirectory.share") }}</span>
                              </v-btn>
                            </v-card>
                            <div class="error-class" v-if="phone" style="color: red">{{ phoneError }}</div>
                          </div>
                        </div>
                      </li>
                      <li v-if="noHasMoreMembers" class="show-more-wrap">
                        <button class="btn customBtn pattern-btn text-capitalize" disabled>
                          <span>No more Members</span>
                        </button>
                      </li>
                      <li v-if="hasMoreMembers" @click="loadMoreMembers(false)" class="show-more-wrap">
                        <button class="show-more-btn btn customBtn pattern-btn">
                          <span>{{ $t("posts.showmore") }}</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="newDashboard-footer" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { VueTelInput } from "vue-tel-input";
import Navbar from "../components/layout/Navbar";
import dateUtil from "@/utils/date.utils";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import SocketMixin from "@/mixins/SocketMixin";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE, ADMIN_TEAM_USERS, ATTENTION_POST_IMAGE, DEBOUNCE_TIMEOUT } from "@/constants/common";

// Janus Related files

const userData = JSON.parse(localStorage.getItem("user_data"));
let is_sip = userData?.is_sip; // Use optional chaining to prevent errors if userData is null

(async () => {
  if (is_sip === 1) {
    const module = await import("@/plugins/audiobridge_sip");
  } else {
    const module = await import("@/plugins/audiobridge");
  }
})();

export default {
  name: "NewDashboard",
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    VueTelInput,
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [SocketMixin.listeners],

  data() {
    return {
      dialog: true,
      selectedCountry: localStorage.getItem("selectedCountry"),

      //first post div
      allPosts: [],
      currentPage: 1,
      hasMorePosts: false,
      noHasMorePosts: false,
      perPage: 25,
      searchText: "",
      imageSlider: false,

      //inventory post div
      inventoryAllPosts: [],
      inventoryCurrentPage: 1,
      inventoryHasMorePosts: false,
      noInventoryHasMorePosts: false,
      inventoryPerPage: 35,
      searchInventoryText: "",
      inventoryImageSlider: false,

      //members div
      allMembers: [],
      memberCurrentPage: 1,
      hasMoreMembers: false,
      noHasMoreMembers: false,
      memberPerPage: 25,
      searchMemberText: "",

      expandedSearch: '',
      images: [],
      search: "",
      dialog: {
        isForCreatePost: false,
        title: "",
        closeButtonText: "",
        saveButtonText: "",
        open: false,
        inventoryOpen: false,
        link: "",
      },

      selectedPost: {},
      loading: false,
      btnLoading: false,
      post: {
        message: null,
      },
      replyOnPostFields: {
        reply: null,
      },
      message: "",
      badgeCount: 0,
      reply: null,
      menu2: false,
      date: new Date().toISOString().substr(0, 10), // Format to YYYY-MM-DD
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],

      errors: {
        message: null,
        reply: null,
        phone: null,
      },
      fileLoader: false,
      image: false,
      adminTeamUser: ADMIN_TEAM_USERS,
      isAttentionPost: false,
      attentionPost: ATTENTION_POST_IMAGE,
      expandedItem: null,
      phone: null,
    };
  },
  validations: {
    message: {
      required,
    },
    reply: {
      required,
    },
    phone: {
      required,
      usFormat: (value) => {
        return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
      },
    },
  },

  computed: {
    ...mapGetters({
      unReadMessagesCount: "messages/getUnReadMessagesCount",
      user: "user/getUser",
      posts: "posts/getPosts",
      inventoryPosts: "posts/getInventoryPosts",
      postThreads: "postThread/getPostThreads",
      imageResponse: "posts/getImageResponse",
      members: "members/getMembers",
    }),

    messageError() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.message"),
          })
        );
      if (this.errors && this.errors.message) {
        return this.errors.message;
      }
      return errors;
    },
    replyError() {
      const errors = [];
      if (!this.$v.reply.$dirty) return errors;
      !this.$v.reply.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.reply"),
          })
        );
      if (this.errors && this.errors.reply) {
        return this.errors.reply;
      }
      return errors;
    },
    phoneError() {
      let error = null;
      if (this.$v.phone.$dirty && !this.$v.phone.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (!this.$v.phone.usFormat) {
        error = this.$t("membersDirectory.phoneFormatIsInvalid", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (this.errors && this.errors.phone) {
        error = this.errors.phone;
      }
      return error;
    },

    isAssociate() {
      return !!this.user.parent_id;
    },
  },
  async mounted() {
    await this.fetchMembers();

    await this.getUnReadMessagesCount();
    this.badgeCount = this.unReadMessagesCount;
    await this.loadMorePosts();
    await this.inventoryLoadMorePosts();

    window.addEventListener("resize", this.checkScreenSize);
    this.checkScreenSizeOnLoad();

    window.addEventListener(
      "storage",
      () => {
        window.location.reload();
      },
      false
    );

    if (!localStorage.getItem("user_data")) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  },
  methods: {
    toggleExpand(item) {
      this.expandedItem = this.expandedItem === item ? null : item;
    },

    toggleSearch(item) {
      this.expandedSearch = this.expandedSearch === item ? null : item;
    },

    ...mapActions({
      getUnReadMessagesCount: "messages/getUnReadMessagesCount",
      getPostThreads: "postThread/getPostThreads",
      getPosts: "posts/getPosts",
      getInventoryPosts: "posts/getInventoryPosts",
      createPost: "posts/createPost",
      createMessages: "messages/createMessages",
      replyMessages: "messages/replyMessages",
      uploadImage: "posts/uploadImage",
      deleteImage: "posts/deleteImage",
      getMembers: "members/getMembers",
      shareMember: "members/shareMember",
    }),

    searchMembers: debounce(function () {
      this.fetchMembers();
    }, DEBOUNCE_TIMEOUT),

    getRowClass(item) {
      var returnClass = '';
      if (this.adminTeamUser.includes(item?.owner?.id)) {
        returnClass = 'announcement-row';
      } else if (item.phone && item.post_type == 0 || item.phone && item.post_type == 1 || !item.phone && item.post_type == 1) {
        returnClass = 'userEnquiry-row';
      }
      return returnClass;
    },

    // First letter  in caps : KK
    capitalizeFirstLetter(value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    },

    append(emoji) {
      this.message += emoji;
    },

    setDialog(isRepliedClicked, item = null, isInventory = false) {
      this.selectedPost = item;
      if (isInventory) {
        this.dialog.open = false;
        this.dialog.inventoryOpen = true;
      } else {
        this.dialog.inventoryOpen = false;
        this.dialog.open = true;
      }
      if (!isRepliedClicked) {
        this.dialog.isForCreatePost = true;
        this.dialog.title = this.$t("posts.createPost");
        this.dialog.closeButtonText = this.$t("common.cancel");
        this.dialog.saveButtonText = this.$t("posts.create");
      } else {
        this.dialog.isForCreatePost = false;
        this.dialog.title = this.$t("posts.replyOnPost");
        this.dialog.closeButtonText = this.$t("common.cancel");
        this.dialog.saveButtonText = this.$t("posts.reply");
        this.dialog.link = this.selectedPost.owner
          ? this.selectedPost.owner.user_detail.company_reviews_link
          : "";
      }
    },

    setInventoryDialog(isRepliedClicked, item = null) {
      this.selectedPost = item;
      if (!isRepliedClicked) {
        this.dialog.isForCreatePost = true;
        this.dialog.title = this.$t("posts.createPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("common.cancel");
        this.dialog.saveButtonText = this.$t("posts.create");
      } else {
        this.dialog.isForCreatePost = false;
        this.dialog.title = this.$t("posts.replyOnPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("common.cancel");
        this.dialog.saveButtonText = this.$t("posts.reply");
        this.dialog.link = this.selectedPost.owner
          ? this.selectedPost.owner.user_detail.company_reviews_link
          : "";
      }
    },

    callDialogMethod() {
      if (this.dialog.title === this.$t("posts.createPost")) {
        this.storePost();
      } else {
        this.replyOnPost(this.selectedPost);
      }
    },
    searchPosts: debounce(function () {
      this.loadMorePosts();
    }, DEBOUNCE_TIMEOUT),

    searchPartsInput: debounce(function () {
      this.inventoryLoadMorePosts();
    }, DEBOUNCE_TIMEOUT),


    async fetchPosts() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.currentPage = 1;
        }
        const startDate = this.date;
        const endDate = this.date;
        await this.getPosts({
          page: this.currentPage,
          items_per_page: this.perPage,
          search: this.searchText,
          start_date: startDate,
          end_date: endDate,
          country: this.selectedCountry,
          post_type: [0,1,3]
        });

        var data = this.posts.data;
        this.allPosts = [...this.allPosts, ...data];

        // Check if there are more pages
        if (this.posts.meta.current_page >= this.posts.meta.last_page) {
          this.hasMorePosts = false; // No more pages
          this.noHasMorePosts = true;
        } else {
          this.hasMorePosts = true;
          this.noHasMorePosts = false;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async loadMorePosts(isFilter = true) {
      if(isFilter) {
        this.currentPage = 1;
        this.allPosts = [];
      } else {
        this.currentPage++;
      }
      this.fetchPosts();
    },

    async inventoryLoadMorePosts(isFilter = true) {
      if(isFilter) {
        this.inventoryCurrentPage = 1;
        this.inventoryAllPosts = [];
      } else {
        this.inventoryCurrentPage++;
      }
      this.fetchInventoryPosts();
    },

    async fetchInventoryPosts() {
      this.loading = true;
      try {
        if (this.searchInventoryText) {
          this.inventoryCurrentPage = 1;
        }
        const startDate = this.date;
        const endDate = this.date;
        await this.getInventoryPosts({
          page: this.inventoryCurrentPage,
          items_per_page: this.inventoryPerPage,
          search: this.searchInventoryText,
          start_date: startDate,
          end_date: endDate,
          country: this.selectedCountry,
          post_type: [2]
        });

        var data = this.inventoryPosts.data;
        this.inventoryAllPosts = [...this.inventoryAllPosts, ...data];

        // Check if there are more pages
        if (this.inventoryPosts.meta.current_page >= this.inventoryPosts.meta.last_page) {
          this.inventoryHasMorePosts = false; // No more pages
          this.noInventoryHasMorePosts = true;
        } else {
          this.inventoryHasMorePosts = true;
          this.noInventoryHasMorePosts = false;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async resetDateFilter() {
      this.date = await new Date().toISOString().substr(0, 10);
      await this.loadMorePosts();
      await this.inventoryLoadMorePosts();
    },

    async storePost() {
      this.$v.message.$touch();
      if (!this.$v.message.$invalid) {
        this.btnLoading = true;
        try {
          var payload = { message: this.message };
          var media_ids = this.image ? this.image.id : '';
          if (media_ids != '') {
            payload = {
              ...payload,
              media_ids: [media_ids]
            }
          }
          payload.country = this.selectedCountry;
          await this.createPost(payload);
          this.message = "";
          this.$v.message.$reset();
          this.$refs['img1'].$el.children[1].value = "";
          this.image = false;
        } catch ({ message }) {
          this.errors.message = message.message;
        } finally {
          this.btnLoading = false;
          this.dialog.open = false;
        }
      }
    },

    async replyOnPost(post) {
      this.$v.reply.$touch();
      if (!this.$v.reply.$invalid) {
        this.btnLoading = true;
        try {
          if (!post.owner) {
            await this.replyMessages({
              post_id: post.id,
              body: this.reply,
            });
          } else {
            await this.createMessages({
              post_id: post.id,
              recipient_id: post.owner.id,
              body: this.reply,
            });
          }
          await this.loadMorePosts();
          await this.getPostThreads({
            page: 1,
            items_per_page: ITEMS_PER_PAGE,
            search: "",
          });
          this.reply = "";
          this.expanded = [];
          this.$v.reply.$reset();
        } catch ({ message }) {
          this.errors.reply = message.body;
        } finally {
          this.dialog.open = false;
          this.btnLoading = false;
        }
      }
    },

    setImageSlider(item) {
      if (item.post_type == 3) {
        item.medias[0].social = item.medias[0].url700;
      }
      this.inventoryImageSlider = false;
      this.imageSlider = true;
      this.images = item.medias;
    },

    setInventoryImageSlider(item) {
      if (item.post_type == 3) {
        item.medias[0].social = item.medias[0].url700;
      }
      this.imageSlider = false;
      this.inventoryImageSlider = true;
      this.images = item.medias;
    },

    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },

    async onCheckAttentionPost() {
      if (this.isAttentionPost) {
        this.image = ATTENTION_POST_IMAGE;
      } else {
        this.image = false;
      }
    },

    async saveImage(file, tag) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        await this.uploadImage(formData);
        this.fileLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if (this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    },

    async fetchMembers() {
      this.loading = true;
      try {
        if (this.searchMemberText) {
          this.memberCurrentPage = 1;
          this.allMembers = [];
        }
        await this.getMembers({
          page: this.memberCurrentPage,
          items_per_page: this.memberPerPage,
          search: this.searchMemberText,
          country: this.selectedCountry
        });

        var data = this.members.data;
        this.allMembers = [...this.allMembers, ...data];

        // Check if there are more pages
        if (this.members.meta.current_page >= this.members.meta.last_page) {
          this.hasMoreMembers = false; // No more pages
          this.noHasMoreMembers = true;
        } else {
          this.hasMoreMembers = true;
          this.noHasMoreMembers = false;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async loadMoreMembers(isFilter = true) {
      if(isFilter) {
        this.memberCurrentPage = 1;
        this.allMembers = [];
      } else {
        this.memberCurrentPage++;
      }
      this.fetchMembers();
    },

    async shareMemberInfo(member) {
      this.$v.phone.$touch();
      if (!this.$v.phone.$invalid) {
        this.loading = true;
        try {
          await this.shareMember({
            member_id: member.id,
            phone: this.phone,
            country: this.selectedCountry
          });
          this.phone = null;
          this.expanded = [];
        } catch ({ message }) {
          this.errors.phone = message;
        } finally {
          this.loading = false;
        }
      }
    },

    checkScreenSize() {
      if (this.$vuetify.breakpoint.width <= 1351) {
        this.$router.push({ name: "Posting" });
      }
    },
    checkScreenSizeOnLoad() {
      if (this.$vuetify.breakpoint.width < 1351) {
        this.$router.push({ name: "Posting" });
      }
    },
  },
};
</script>
